footer {
    background-color: #222;
    padding-top: 0;
    color: #ccc;
    height: 50px;
    position: fixed;
    bottom: 0;
    width: 100%;

    .copyright-section {
        padding: 15px 0;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: .5px;
    }
}