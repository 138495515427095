body {
    padding: 0 !important;
}

h1,
h2,
p {
    font-family: sans-serif;
}

td .btn {
    margin: 2px;
}

// h1,
// h2,
// h3 {
//     margin-top: 20px !important;
// }


.btn:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ReactModal__Overlay {
    z-index: 2000;
}

.error {
    color: red;
}

.notification-wrapper {
    z-index: 1002;
}

.toast-notification {
    z-index: 1002 !important;
}

.main {
    margin-top: 56px;
    margin-bottom: 50px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.8);
    min-height: calc(100vh - 106px) !important;

    @media (min-width: 768px) {
        margin-top: 68px;
    min-height: calc(100vh - 118px) !important;
}

    .carousel-root {
        padding: 34px 35px 240px;

        @media (min-width: 768px) {
            background-image: url('./assets/images/home_.png');
            background-size: 100%;
            background-repeat: no-repeat;
        }

        @media (min-width: 1200px) {
            margin: 0 45px;
        }

        @media (max-width: 991px) {
            padding: 25px 26px 160px;
        }
    }
}

.app-body {
    position: absolute;
    width: 100%;
    background-color: #e4e5e6;
    background: url('./assets/images/background.png') center top 40px / cover no-repeat fixed;
    min-height: calc(100vh);

    @media (min-width: 768px) {
        background: url('./assets/images/background.png') center top 50px / cover no-repeat fixed;
    }
}

.card {
    margin-top: 15px;
}

.row_right {
    text-align: right;
}

.padding15 {
    padding: 15px 0;
}

.row-event {
    margin: 0 !important;
}

.row-event:hover {
    background-color: rgba(0, 0, 0, .075);
}

.row-event div {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.row-event .event-date,
.row-event .event-competitors {
    text-align: left;
    font-size: small;
    margin-bottom: 0;
}

.row-event .event-name {
    font-size: x-large;
}