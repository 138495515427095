
.livezone-navbar {
    position: fixed !important;
    width: 100%;
    z-index: 1001;
    min-height: 56px;
    font-size: 18px;

    .livezone-brand {
        padding: 0 !important;
      
        img {
            height: 40px;
        }
    }
    
    .livezone-nav {
        width: 100%;
    
        @media (min-width: 768px) {
            li:nth-last-child(2) {
                margin-left: auto;
            }
        }
    }

    .mobile_notifications {
        position: absolute;
        right: 70px;
        top: 40px;
    }

    .mobile {
      display: block;
    }
    
    .desktop {
      display: none;
    }
    
    .inline {
      display: inline-block;
    }

    @media (min-width: 768px) {
        min-height: 76px;

        .mobile {
            display: none;
        }
      
        .desktop {
          display: block;
        }
      
        .livezone-nav .nav-item .notifications {
          position: absolute;
          bottom: 5px;
          right: 5px;
        }
    }

    .nav-item {
        padding: 15px 10px 0 10px;
        
        .nav-link {
            color: #ff0066 !important;
        
            &::before {
                color: rgba(0,0,0,.7);
                display: block;
                content: attr(title);
                font-weight: bold;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }

            &.active,
            &:focus,
            &:hover {
                font-weight: bold;
            }
        }
    }

    .dropdown-item.active, .dropdown-item:active {
        background: inherit !important;
    }
}